import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import loadable from '@loadable/component';

import { getLayoutHomepageSettings } from 'common/selectors/theme';
import {
  getLastSeenProductsSelector,
  getHomepageArticlesSelector,
  getPageDataSelector
} from 'common/selectors/page';
import { fetchLastSeenProducts } from 'common/actions/PageActions';

const Column = loadable(() =>
  import('common/components/styleguide/grid/Column')
);
const Row = loadable(() => import('common/components/styleguide/grid/Row'));
const Container = loadable(() =>
  import('common/components/styleguide/grid/Container')
);

const Banners = loadable(() =>
  import('common/components/home/banners/_default')
);

import { Block } from 'common/components/styleguide/grid';
import { Headline2 } from 'common/components/styleguide/Headline';

const NewProducts = loadable(() => import('common/blocks/products/new'));
const FavouriteProducts = loadable(() =>
  import('common/blocks/products/favourite')
);
const NewArrivals = loadable(() =>
  import('common/components/home/newarrivals')
);
const Blog = loadable(() => import('common/blocks/blog/element'));
const Testimonial = loadable(() => import('common/blocks/testimonial/element'));
const Contact = loadable(() => import('common/blocks/contact/element'));
import Static from 'common/components/home/static';

const FooterProducts = loadable(
  () => import('common/components/home/FooterProducts'),
  { ssr: false }
);
const Newsletter = loadable(
  () => import('common/blocks/functional/newsletter'),
  { ssr: false }
);
import { capfl } from 'common/utils';

import SS from 'common/pages/_style/index';

class Home extends PureComponent {
  componentDidMount() {
    const { setLastSeenProducts } = this.props;
    setLastSeenProducts();
  }
  render() {
    const { t, gotLastSeen, homepageSettings, articles, testimonials = []} = this.props;
    return (
      <Fragment>
        {_.get(homepageSettings, 'isBannersEnabled', true) && (
          <Banners
            useContainer={true}
            positionBanner={'BannerHomepageMainVariant'}
          />
        )}

        <Static />

        <Static location={'staticPages2'} />

        {_.get(homepageSettings, 'isProductsNewsEnabled', true) && (
          <Container>
            <Block
              marginConfig={{}}
              paddingConfig={{ top: true, bottom: true }}
            >
              <NewProducts
                headline={<Headline2>{capfl(t('new arrivals'))}</Headline2>}
                gtm={{ list: 'HomepageNew' }}
              />
            </Block>
          </Container>
        )}

        <Static location={'staticPages3'} />

        {_.get(homepageSettings, 'isProductsFavouriteEnabled', true) && (
          <Container>
            <Block
              marginConfig={{}}
              paddingConfig={{ top: true, bottom: true }}
            >
              <FavouriteProducts
                headline={<Headline2>{capfl(t('favourite'))}</Headline2>}
                gtm={{ list: 'HomepageFavourite' }}
              />
            </Block>
          </Container>
        )}

        <Static location={'staticPages4'} />

        {_.get(homepageSettings, 'isProductsLatestEnabled', true) && (
          <Block marginConfig={{}} paddingConfig={{ top: true, bottom: true }}>
            <Container>
              <Headline2>{capfl(t('latests products'))}</Headline2>
              <NewArrivals />
            </Container>
          </Block>
        )}

        {!_.isEmpty(testimonials) && <Testimonial data={testimonials} />}
        {!_.isEmpty(articles) && <Blog articles={articles} />}
        <Contact />

        {_.get(homepageSettings, 'isProductsLastSeenEnabled', true) &&
          gotLastSeen && (
            <SS.SectionFooterProducts>
              <Container>
                <Row>
                  <Column size={{ xs: 12 }}>
                    <SS.SectionTitle>{capfl(t('last seen'))}</SS.SectionTitle>
                  </Column>
                </Row>
                <Row>
                  <Column size={{ xs: 12 }}>
                    <SS.SectionContent>
                      <FooterProducts />
                    </SS.SectionContent>
                  </Column>
                </Row>
              </Container>
            </SS.SectionFooterProducts>
          )}

        <Static location={'staticPages5'} />

        {_.get(homepageSettings, 'isNewsletterEnabled', true) && (
          <Block>
            <Container>
              <Row>
                <Column size={{ xs: 12 }}>
                  <Newsletter />
                </Column>
              </Row>
            </Container>
          </Block>
        )}
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getLastSeenProducts = getLastSeenProductsSelector();
  const homepageSettings = getLayoutHomepageSettings();
  const getArticles = getHomepageArticlesSelector();
  const getPageData = getPageDataSelector();
  return (state, props) => {
    const lastSeenProducts = getLastSeenProducts(state, props);
    return {
      gotLastSeen: !_.isEmpty(lastSeenProducts),
      homepageSettings: homepageSettings(state, props),
      articles: getArticles(state, props),
      testimonials: _.get(getPageData(state, props), 'testimonials')
    };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLastSeenProducts: () => {
      dispatch(fetchLastSeenProducts());
    }
  };
};

const HomeConnected = connect(makeMapStateToProps, mapDispatchToProps)(Home);

export default withTranslation()(HomeConnected);
